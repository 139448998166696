/* eslint-disable react/no-danger */
import React from 'react';
import Page from './Page';

const Template = ({ children, pageContext }) => {
  const { frontmatter } = pageContext;
  // const post = data.markdownRemark;
  const { title } = frontmatter;
  return (
    <Page {...frontmatter}>
      <h1>{title}</h1>
      <div>{children}</div>
    </Page>
  );
};

export default Template;
